import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"320","light":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"text-center"},[_c('div',{staticClass:"pt-5"},[_c(VIcon,{staticClass:"cust-icon",attrs:{"x-large":"","color":"red","dark":""}},[_vm._v(" mdi-alert ")])],1),_c(VCardTitle,{staticClass:"justify-center py-0"},[_c('h4',[_vm._v("Confirmation !")])]),_c(VCardText,{staticClass:"py-0"},[_vm._v("Are you sure to "+_vm._s(_vm.text)+"?")]),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.$emit('confirm')}}},[_vm._v("Yes")]),_c(VBtn,{attrs:{"color":"error","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("No")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }